import { createContext, useContext, useState } from 'react';

export const CsrfContext = createContext({});

export const useCsrfToken = () => useContext(CsrfContext);

export const CsrfContextProvider = ({ children, token }) => {
    return (
        <CsrfContext.Provider value={token}>{children}</CsrfContext.Provider>
    );
};
