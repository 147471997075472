import querystring from 'querystring';
import { getPage, getRedirect, WagtailApiResponseError } from '../api/wagtail';
import LazyContainers from '../containers/LazyContainers';
import { CsrfContextProvider } from '../context/CsrfContext';
import { PageContextProvider } from '../context/PageContext';
import composeServerSideProps from '../utils/compose-ssp';
import { ACCESS_TOKEN_COOKIE_NAME } from '../utils/openIDToken';
import cookie from 'cookie';

const isProd = process.env.NODE_ENV === 'production';

export default function CatchAllPage({ componentName, componentProps }) {
    const Component = LazyContainers[componentName];
    if (!Component) {
        return <h1>Component {componentName} not found</h1>;
    }
    return (
        <CsrfContextProvider token={componentProps.csrfToken}>
            <PageContextProvider page={componentProps}>
                <Component {...componentProps} />
            </PageContextProvider>
        </CsrfContextProvider>
    );
}

// For SSR
export async function getServerSideProps(ctx) {
    const { req, params, res } = ctx;

    let path = params?.path || [];
    path = path.join('/');

    const { host } = req.headers;

    let queryParams = new URL(req.url, `https://${host}`).search;
    if (queryParams.indexOf('?') === 0) {
        queryParams = queryParams.substr(1);
    }
    queryParams = querystring.parse(queryParams);

    queryParams = {
        ...queryParams,
        host,
    };

    let cookies = {};
    if (req.headers.cookie) {
        cookies = cookie.parse(req.headers.cookie);
    }

    const firstPath = path.split('/')[0];
    if (['privat', 'foretag', 'private', 'company'].indexOf(firstPath) !== -1) {
        if (cookies.selectedSite != firstPath) {
            cookies['selectedSite'] = firstPath;
        }
    }

    if (cookies.selectedSite) {
        queryParams['selectedSite'] = cookies.selectedSite;
    }

    // Try to serve page
    try {
        let {
            json: { componentName, componentProps, redirect, customResponse },
            headers,
        } = await getPage(path, queryParams, {
            headers: {
                cookie: req.headers.cookie,
            },
        });

        // Redirect / to the subsite set in cookie, fall back to first site in tab-area
        if (componentName === 'HomePage') {
            const fallbackPath = componentProps.siteSetting.header.tabs[0].href;
            const path = req.cookies?.selectedSite
                ? '/' + req.cookies?.selectedSite + '/'
                : fallbackPath;

            return {
                redirect: {
                    destination: path,
                    isPermanent: false,
                },
            };
        }

        if (componentProps.onMyPages) {
            componentProps = {
                isLoggedInOnMyPages: !!cookies[ACCESS_TOKEN_COOKIE_NAME],
                ...componentProps,
            }
        }

        // Make sure user is logged in before serving page
        if (componentProps.onMyPages && componentProps.requireCustomerSession) {
            if (!cookies[ACCESS_TOKEN_COOKIE_NAME]) {
                return {
                    redirect: {
                        destination: `/api/mypages/authenticate/?next=/${path}/`,
                        isPermanent: false,
                    },
                };
            }
        }

        if (!!queryParams.error) {
            componentProps = {
                error: queryParams.error,
                ...componentProps,
            }
        }

        // Forward any cookie we encounter
        if (headers.get('set-cookie')) {
            res.setHeader('Set-Cookie', headers.get('set-cookie'));
        }

        res.setHeader(
            'Cache-Control',
            'public, s-maxage=30, stale-while-revalidate=59'
        );

        if (customResponse) {
            const { body, body64, contentType } = customResponse;
            res.setHeader('Content-Type', contentType);
            res.statusCode = 200;
            res.write(body64 ? Buffer.from(body64, 'base64') : body);
            res.end();

            return { props: {} };
        }

        if (redirect) {
            const { destination, isPermanent } = redirect;
            return {
                redirect: {
                    destination: destination,
                    permanent: isPermanent,
                },
            };
        }

        return composeServerSideProps(
            [],
            ctx,
            {
                props: {
                    componentName,
                    componentProps: {
                        ...componentProps,
                        // TODO: Remove this
                        customerId: cookies.customerId || '',
                    },
                },
            },
            { locale: componentProps.siteSetting.language }
        );
    } catch (err) {
        if (!(err instanceof WagtailApiResponseError)) {
            throw err;
        }

        // When in development, show django error page on error
        if (!isProd && err.response.status >= 500) {
            const html = await err.response.text();
            return composeServerSideProps(
                [],
                ctx,
                {
                    props: {
                        componentName: 'PureHtmlPage',
                        componentProps: { html },
                    },
                },
                { locale: ctx.locale }
            );
        }

        if (err.response.status >= 500) {
            throw err;
        }
    }

    // Try to serve redirect
    try {
        const { json: redirect } = await getRedirect(path, queryParams, {
            headers: {
                cookie: req.headers.cookie,
                host,
            },
        });
        const { destination, isPermanent } = redirect;
        return {
            redirect: {
                destination: destination,
                permanent: isPermanent,
            },
        };
    } catch (err) {
        if (!(err instanceof WagtailApiResponseError)) {
            throw err;
        }

        if (err.response.status >= 500) {
            throw err;
        }
    }

    // Serve 404 page
    return { notFound: true };
}

// For SSG
/*
export async function getStaticProps({ params, preview, previewData }) {
    params = params || {};
    let path = params.path || [];
    path = path.join("/");

    const { json: pageData } = await getPage(path);
    return { props: pageData }
}

export async function getStaticPaths() {
    const { json: data } = await getAllPages();

    let htmlUrls = data.items.map(x => x.relativeUrl);
    htmlUrls = htmlUrls.filter(x => x);
    htmlUrls = htmlUrls.map(x => x.split("/"));
    htmlUrls = htmlUrls.map(x => x.filter(y => y))
    htmlUrls = htmlUrls.filter(x => x.length)

    const paths = htmlUrls.map(x => (
        { params: { path: x } }
    ));

    return {
        paths: paths,
        fallback: false,
    };
}
*/
